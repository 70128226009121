import { Pipe, PipeTransform } from '@angular/core';

import { getDateString } from '../functions/get-date-string';

@Pipe({
  name: 'localeDateString',
  standalone: true,
})
export class LocaleDateStringPipe implements PipeTransform {
  transform(value: Date | string): string {
    return getDateString(value);
  }
}
