import { JsonObject } from '@stsm/shared/types/json-object';
import { KeyMapping } from '@stsm/shared/types/key-mapping';
import { fromJsonHelper } from '@stsm/shared/util/json-util';

export const systemMaintenanceBannerDelay: number = 2000;

const keyMapping: KeyMapping<SystemMaintenanceInfo> = {
  plannedMaintenanceStart: 'planned_maintenance_start',
  plannedMaintenanceEnd: 'planned_maintenance_end',
  isMaintenanceOngoing: 'maintenance_ongoing',
};

export interface SystemMaintenanceInfo {
  plannedMaintenanceStart?: string;
  plannedMaintenanceEnd?: string;
  isMaintenanceOngoing?: boolean;
}

export class SystemMaintenanceInfoSerializer {
  static fromJson(json: JsonObject): SystemMaintenanceInfo {
    return fromJsonHelper({
      json,
      keyMapping,
    });
  }
}
